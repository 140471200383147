body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Maledpan";
  src: url("./assets/fonts/maledpan.woff2") format("woff2"), url("./assets/fonts/maledpan.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Maledpan";
  src: url("./assets/fonts/maledpan-bold.woff2") format("woff2"), url("./assets/fonts/maledpan-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}